import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MenuItem, Button } from '@material-ui/core'

import { OutlinedTextField, OutlinedCheckBox, OutlinedSelect } from '../../common/fields'
import { RespButton } from '../../common/buttons'
import { ActionPanelHeader } from '../shared-components/ActionPanel'
import { postSim } from '../../common/utils'
import { useLogin } from '../../common/LoginContext'

import { ConsumesLicenseDialog } from './ConsumesLicenseDialog'
import { DEFAULT_MEMBER_FIELDS, ROLES } from '../shared-components/sharedObjects'
import { useNavigate } from 'react-router-dom'
import { useGroups } from '../../contexts/GroupContext'


const useStyles = makeStyles(theme => ({
  dialogContent: {
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    gap: 20,
    padding: '5px',
    paddingTop: '25px',
  },
  wideField: {
    marginLeft: 29,
    marginRight: 29,
  },
}))

function EditMemberActionPanel(props) {
  const classes = useStyles()
  const [memberFields, setMemberFields] = React.useState(DEFAULT_MEMBER_FIELDS())
  const [fetchStatus, setFetchStatus] = React.useState(null)
  const [consumesDialogOpen, setConsumesDialogOpen] = React.useState(false)
  const [loginState] = useLogin()
  const { selectedGroup } = useGroups()
  const navigate = useNavigate()
  const memberData = props.memberData

  console.log(selectedGroup)

  const sharedGroups = props?.groups?.filter(group => group.licenseType === 'Assigned' && group.id !== props.groupId)

  React.useEffect(() => {
    const memberFields = DEFAULT_MEMBER_FIELDS()
    if (memberData){
      memberFields.name = memberData.name
      memberFields.email = memberData.email
      memberFields.role = memberData.role
      memberFields.consumesLicense = memberData.consumesLicense
    } else {
      memberFields.name = ''
      memberFields.email = ''
      memberFields.role = ''
      memberFields.consumesLicense = false
    }
    setMemberFields(memberFields)
  }, [memberData])

  const onFieldChange = (key, value) => {
    var copyOfFields = {...memberFields}
    copyOfFields[key] = value
    setMemberFields(copyOfFields)
  }

  const saveChange = async () => {
    return await postSim(`groups/${props.groupId}/members/${memberData.userId}?role=owner`, loginState?.user, {})
  }

  const saveChangeSuccess = () => {
    onFieldChange('consumesLicense', !memberFields.consumesLicense)
    props.editGroupMemberSuccess()
  }


  function openLessonAccessControlPage() {
    navigate('lesson-access-control')
  }

  return (
    <>
      <ActionPanelHeader
        title={props.title}
        waiting={false}
        handleClose={props.handleClose}
      />
      <div className={classes.dialogContent}>

        <OutlinedTextField
          className={classes.wideField}
          label={'Member Name'}
          onChange={ev=>onFieldChange('name', ev.target.value)}
          value={memberFields.name}
          disabled
        />
        <OutlinedTextField
          className={classes.wideField}
          label={'Member Email'}
          onChange={ev=>onFieldChange('email', ev.target.value)}
          value={memberFields.email}
          disabled
        />
        <OutlinedSelect
          value={memberFields.role}
          onChange={ev=>onFieldChange('role', ev.target.value)}
          label='Role'
          className={classes.wideField}
          disabled
        >
          {ROLES.map(role => (
            <MenuItem key={role} value={role}>{role}</MenuItem>
          ))}
        </OutlinedSelect>
        {selectedGroup.licenseType === 'Assigned' ?
          sharedGroups && sharedGroups.length > 0 && memberFields.consumesLicense ?
            <div className={classes.wideField}>
              <OutlinedCheckBox
                onClick={()=>setConsumesDialogOpen(true)}
                label={'Omit From License'}
                checked={!memberFields.consumesLicense}
                disabled={memberFields.role === 'owner'}
              />
            </div> :
            <RespButton
              color='secondary'
              buttonComponent={OutlinedCheckBox}
              onClick={(ev)=>{
                return saveChange(!memberFields.consumesLicense)
              }}
              label='Omit From License'
              checked={!memberFields.consumesLicense}
              successCb={saveChangeSuccess}
              status={fetchStatus}
              setStatus={setFetchStatus}
              disabled={memberFields.role === 'member'}
              symmetrical
            /> :
          null}
        {selectedGroup.missionPackId !== 'HWN' &&
        <div style={{backgroundColor: 'rgba(76, 175, 80, 0.7)', marginBottom: 20, borderRadius: 3}} className={classes.wideField}>
          <Button
            // className={classes.wideField}
            onClick={openLessonAccessControlPage}
            variant='outlined'
          >
            {'Open Student In Lesson Access Manager'}
          </Button>
        </div>
        }
        <ConsumesLicenseDialog
          open={consumesDialogOpen}
          onClose={()=>setConsumesDialogOpen(false)}
          sharedGroups={sharedGroups}
          groups={props.groups}
          groupId={props.groupId}
          memberFields={memberFields}
          memberData={memberData}
          respButtonOnClick={()=>saveChange(!memberFields.consumesLicense)}
          fetchStatus={fetchStatus}
          setFetchStatus={setFetchStatus}
          successCb={saveChangeSuccess}
        />
      </div>
    </>
  )
}

export { EditMemberActionPanel }